import React from "react"
import Header from "../../components/Header/Header"
import styles from "./Policy.module.scss"
import Footer from "../../components/footer/Footer"
import { HelmetProvider, Helmet } from "react-helmet-async"

export default function Policy() {
  const returnPolicy = {
    title: "Return Policy",
    sections: [
      {
        heading: "General Return Policy",
        content:
          "At CompuOneLB, we strive to ensure customer satisfaction with every purchase. If you're not completely satisfied with your product, we offer returns under the following conditions.",
      },
      {
        heading: "Eligibility for Returns",
        content: [
          "Products must be returned within 14 days of purchase.",
          "Items must be in their original condition, unused, and in the original packaging.",
          "Certain products such as software, digital goods, and clearance items may not be eligible for return.",
          "Proof of purchase is required for all returns.",
        ],
      },
      {
        heading: "Return Process",
        content: [
          "To initiate a return, please contact our customer service team at +961 09 221 062 within 14 days of receiving your product.",
          "Our team will provide you with a return authorization and instructions on how to ship the item back to us.",
          "Customers are responsible for return shipping costs unless the return is due to a defect or shipping error on our part.",
        ],
      },
      {
        heading: "Refunds",
        content: [
          "Once your return is received and inspected, we will notify you of the approval or rejection of your refund.",
          "If approved, refunds will be processed to the original method of payment within 7-10 business days.",
          "Original shipping fees are non-refundable.",
        ],
      },
      {
        heading: "Exchanges",
        content: [
          "If you wish to exchange a product for a different item, please follow the same process as returns.",
          "Exchanges are subject to product availability.",
          "Any price differences between the exchanged items will either be refunded or charged accordingly.",
        ],
      },
      {
        heading: "Non-Returnable Items",
        content: [
          "Certain items are not eligible for return, including but not limited to:",
          "    - Downloadable software products",
          "    - Gift cards",
          "    - Clearance or final sale items",
          "    - Products damaged due to misuse or improper handling",
        ],
      },
      {
        heading: "Contact Us",
        content:
          "For any questions or concerns about your return, please reach out to our customer service team at +961 09 221 062.",
      },
    ],
  }
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Compuone | Return Policy</title>
          <meta
            name="description"
            content="Discover CompuOneLB's hassle-free return policy, ensuring smooth returns and exchanges for all your tech purchases"
          />
          <link rel="canonical" href={process.env.REACT_APP_URL + "/policy"} />

          <meta charset="UTF-8" />

          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />

          <meta property="og:title" content="Compuone | Return Policy" />
          <meta
            property="og:description"
            content="Discover CompuOneLB's hassle-free return policy, ensuring smooth returns and exchanges for all your tech purchases"
          />
          <meta
            property="og:url"
            content={process.env.REACT_APP_URL + "/policy"}
          />
          <meta property="og:type" content="website" />
          <meta
            property="og:image"
            content="https://www.compuonelb.com/static/media/logo.704b86f20af085db0b41.png"
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="Compuone | Return Policy" />
          <meta
            name="twitter:description"
            content="Discover CompuOneLB's hassle-free return policy, ensuring smooth returns and exchanges for all your tech purchases"
          />
          <meta name="twitter:url" content={process.env.REACT_APP_URL} />
          <meta
            name="twitter:image"
            content="https://www.compuonelb.com/static/media/logo.704b86f20af085db0b41.png"
          />
          <meta
            name="keywords"
            content="CompuOneLB return policy, tech returns, product exchanges, hassle-free returns, technology purchase returns"
          />
        </Helmet>
      </HelmetProvider>
      <Header />
      <main id={styles.main_container}>
        <h1>{returnPolicy.title}</h1>
        {returnPolicy.sections.map((section, index) => {
          return (
            <div key={index}>
              <h2>{section.heading}</h2>
              {Array.isArray(section.content) ? (
                <ul>
                  {section.content.map((p, key) => {
                    return <li key={key}>{p}</li>
                  })}
                </ul>
              ) : (
                <p>{section.content}</p>
              )}
            </div>
          )
        })}
      </main>
      <Footer />
    </>
  )
}
