import React from "react"

import styles from "./AdminAuth.module.scss"
import Logo from "../../../assets/logo.png"
import { useNavigate } from "react-router-dom"

export default function AdminAuth() {
  const navigate = useNavigate()
  return (
    <main className={styles.mains}>
      <img
        onClick={() => navigate("/")}
        style={{ cursor: "pointer" }}
        src={Logo}
        alt="compuone_logo"
      />
      <h1>Admin Only</h1>
      <h2>Access restrictred for admin only</h2>
    </main>
  )
}
