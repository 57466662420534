import React, { useEffect, useState } from "react"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import Home from "./routes/Home"
import axios from "axios"
import Auth from "./routes/auth/Auth"
import { getUserData, setActualCountry } from "./state/authSlice"
import { useDispatch, useSelector } from "react-redux"
import MainAdmin from "./routes/admin/MainAdmin"
import ProductsPage from "./routes/products/ProductsPage"
import ProductPage from "./routes/products/Product/ProductPage"
import Contact from "./routes/Contact"

import PageNotFound from "./routes/NotFound/PageNotFound"
import { getOrderById } from "./state/orderSlice"
import { getContactByCountry } from "./state/seoSlice"

import CartPage from "./routes/cart/CartPage"
import { getMaxClicks, getShowPrice } from "./helpers/Products"
import { setMaxClicks, setShowPrice } from "./state/productSlice"
import { getCategories } from "./state/categoriesSlice"
import Countries from "../src/helpers/Countries"
import { Helmet, HelmetProvider } from "react-helmet-async"
import About from "./routes/About"
import News from "./routes/news/News"
import NewsList from "./routes/newList/NewsList"
import Policy from "./routes/Policy/Policy"

function App() {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getCategories())
  })
  axios.defaults.baseURL = process.env.REACT_APP_BASE_URL
  const { user_id, currentCountry, token, verified, accessLevel, country } =
    useSelector((state) => state.auth)

  let c = ""

  async function asycnFunc() {
    const res = await axios.get("https://api.ipify.org/?format=json")
    const ip = res.data.ip
    const countryCode = (await axios.get(`https://ipinfo.io/${ip}/json`)).data
      .country
    Countries.forEach((co) => {
      if (co.code === countryCode) {
        c = co.name
      }
    })
  }
  const { products } = useSelector((state) => state.product)

  useEffect(() => {
    const payload = {
      id: user_id,
    }
    //get User

    dispatch(getUserData(payload))

    if (accessLevel === "admin" || accessLevel === "super-admin") {
      c = country
      dispatch(setActualCountry(c))

      dispatch(getContactByCountry(c))
    } else {
      if (navigator.userAgent === "ReactSnap") {
        dispatch(setActualCountry("guest"))

        dispatch(getContactByCountry("guest"))
      } else {
        asycnFunc().then(() => {
          dispatch(setActualCountry(c))

          dispatch(getContactByCountry(c))
        })
      }
    }

    const orderId = localStorage.getItem("cart_key")
    //get order

    if (orderId) {
      dispatch(getOrderById(orderId))
    }
    if (products.length !== 0) {
      const maxClicks = getMaxClicks(products)

      dispatch(setMaxClicks(maxClicks))
    }
  }, [dispatch, user_id, accessLevel, country, currentCountry])

  useEffect(() => {
    const showPrice = getShowPrice(country, currentCountry, token, verified)
    dispatch(setShowPrice(showPrice))
  }, [c, currentCountry, token, verified, dispatch])

  return (
    <div className="App">
      <HelmetProvider>
        <Helmet>
          <link rel="icon" type="image/png" href="/favicon.ico" sizes="16x16" />
          <link rel="apple-touch-icon" href="/favicon.ico" />
          <meta name="author" content="Compuone" />
        </Helmet>
      </HelmetProvider>

      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/policy" element={<Policy />} />
          <Route path="/home/:page" element={<Home />} />
          <Route
            path="/:category/:subCat/:page"
            element={<ProductsPage sub="true" />}
          />
          <Route path="/:category/:page" element={<ProductsPage />} />

          <Route path="/contact-us" element={<Contact />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/auth/:state" element={<Auth />} />
          <Route path="/db-admin" element={<MainAdmin />} />
          <Route path="/:url" element={<ProductPage />} />
          <Route path="/cart/:orderId/:step" element={<CartPage />} />
          <Route path="/news/:id" element={<News />} />
          <Route path="/news" element={<NewsList />} />
          <Route path="404" element={<PageNotFound />} />
        </Routes>
      </Router>
    </div>
  )
}

export default App
