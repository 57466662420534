import React, { useEffect, useState, useRef } from "react"
import Footer from "../../../components/footer/Footer"
import Header from "../../../components/Header/Header"

import styles from "./ProductPage.module.scss"
import { useNavigate, useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import {
  fetchProduct,
  fetchProducts,
  updateRating,
} from "../../../state/productSlice"
import ImageZoomer from "../../../components/imageZoomer/ImageZoomer"
import countries from "../../../helpers/Countries"

import HorizontalList from "../../../components/ProductsList/horizontalList/HorizontalList"

import { getMeta } from "../../../state/seoSlice"

import { sortProductsByCat } from "../../../helpers/Products"

import { HelmetProvider, Helmet } from "react-helmet-async"
import CustomLoading from "../../../components/custom/loading/CustomLoading"
import { createOrder, updateOrder } from "../../../state/orderSlice"
import { InlineShareButtons } from "sharethis-reactjs"
import { Rating } from "react-simple-star-rating"
import { useMediaPredicate } from "react-media-hook"
import Layout from "../../../components/layout/Layout"
import Check from "../../../icons/Check"
import { formatUrl } from "../../../helpers/Formats"

export default function ProductPage() {
  const lessThen850 = useMediaPredicate("(max-width: 850px)")

  ///Rating

  //States
  const [isEU, setIsEU] = useState(false)
  const [quantity, setQuantity] = useState(0)
  const [canOrder, setCanOrder] = useState(false)
  const [added, setAdded] = useState(false)
  //navigation
  const navigate = useNavigate()
  //api calls
  const dispatch = useDispatch()
  useEffect(() => {})
  //order state
  const { order } = useSelector((state) => state.orders)
  const { showPrice } = useSelector((state) => state.product)

  let cartPayload = {
    orderId: !order ? "" : order.orderId,
    orderDate: !order ? "" : order.orderDate,
    orderStatus: !order ? "" : order.orderStatus,
    totalPrice: !order ? "" : order.totalPrice,
    products: !order ? "" : order.products,
    user: !order ? "" : order.user,
    paymentMethod: !order ? "" : order.paymentMethod,
    paymentStatus: !order ? "" : order.paymentStatus,
    checkedOut: !order ? "" : order.checkedOut,
  }

  //product state
  const { product, products } = useSelector((state) => state.product)
  const { brands } = useSelector((state) => state.brands)
  let currentBrand = { url: "/" }
  if (product) {
    currentBrand = brands.filter((e) => e.brand === product.brand)[0]
  }

  //auth state
  const { type, country, accessLevel, currentCountry, user_id } = useSelector(
    (state) => state.auth
  )

  //Loading handler

  const [productLoading, setProductLoading] = useState(true)
  const [seoLoading, setSeoLoading] = useState(true)

  //get the id from the url
  const params = useParams()

  // getting if country is eu
  useEffect(() => {
    countries.map((country) => {
      if (country.name === currentCountry && country?.eu === true) {
        setIsEU(true)
      }
    })
  }, [currentCountry])

  //fetch the product by id

  useEffect(() => {
    let payload = {
      url: params.url,
      type: type,
      country: country || currentCountry,
    }

    if (navigator.userAgent === "ReactSnap") {
      payload.type = "ReactSnap"
    }
    if (country !== "" || currentCountry !== "") {
      dispatch(fetchProduct(payload))
        .then((e) => {
          setProductLoading(e.meta.requestStatus !== "fulfilled")
          if (e.payload === undefined) {
            navigate("/404")
          }
        })
        .finally(() => {
          dispatch(getMeta()).then(() => setSeoLoading(false))
        })
    }
  }, [dispatch, country, currentCountry])

  useEffect(() => {
    const payload = {
      subCat: "all",
      cat: "all",
      country: country,
      accessLevel: accessLevel,
    }
    dispatch(fetchProducts(payload))
  }, [dispatch, country])

  useEffect(() => {
    if (added) {
      setTimeout(() => {
        setAdded(false)
      }, 10000)
    }
  }, [added])

  // adjusting the order status
  useEffect(() => {
    if (!productLoading) {
      const isUnique = product.components.length === 0

      setCanOrder(isUnique && showPrice && currentCountry !== "")
    }
  }, [productLoading, currentCountry, showPrice])

  const pageUrl = window.location.href

  // Catch Rating value
  const handleRating = (rate) => {
    const payload = {
      id: product._id,
      rating: rate,
    }
    dispatch(updateRating(payload))
  }

  let productsFiltered = [...products]

  if (product) {
    productsFiltered = productsFiltered.filter((p) => product._id !== p._id)
    productsFiltered = sortProductsByCat(productsFiltered, product.category)
  }

  function isSoldOut() {
    return product.isOutOfStock
  }

  function showPriceComponent() {
    if (product.isCall) {
      return <p>Call for price</p>
    }
    if (showPrice) {
      return (
        <h3>
          {isEU ? "€" : "$"}
          {product.price}
        </h3>
      )
    }
  }

  return (
    <>
      {!seoLoading && (
        <HelmetProvider>
          <Helmet>
            <title>{product.metaTitle}</title>

            <link
              rel="canonical"
              href={
                process.env.REACT_APP_URL + "/" + formatUrl(product.title) + "/"
              }
            />

            <meta name="description" content={product.metaDescription} />
            <meta property="og:title" content={product.metaTitle} />
            <meta property="og:description" content={product.metaDescription} />
            <meta
              property="og:image"
              content={
                process.env.REACT_APP_BASE_URL + product.image_collection[0]
              }
            />
            <meta property="og:url" content={pageUrl} />
            <meta name="twitter:title" content={product.metaTitle} />
            <meta
              name="twitter:description"
              content={product.metaDescription}
            />
            <meta
              name="twitter:image"
              content={
                process.env.REACT_APP_BASE_URL + product.image_collection[0]
              }
            />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="keywords" content={product.metaKeywords} />
            <script type="application/ld+json">
              {`{
    "@context": "https://schema.org/",
    "@type": "Product",
    "name": '${product.title}',
    "image": ${process.env.REACT_APP_BASE_URL + product.image_collection[0]}",
    "description": '${product.description}',
    "sku": "${product._id}",
    "brand": {
      "@type": "Brand",
      "name": "${product.brand}"
    },
    "offers": {
      "@type": "Offer",
      "url": "${process.env.REACT_APP_BASE_URL + formatUrl(product.title)}",
      "priceCurrency": "USD",
      "price": ${product.price},
      "itemCondition": "https://schema.org/NewCondition",
      "availability": "https://schema.org/InStock",
      "seller": {
        "@type": "Organization",
        "name": "CompuOne"
      }
    },
    
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": ${product.rating || 1},
      "reviewCount": ${product.clicks || 1}
    }
  }`}
            </script>
          </Helmet>
        </HelmetProvider>
      )}
      <Header />

      <main className={styles.main}>
        <Layout />
        {productLoading ? (
          <CustomLoading />
        ) : (
          <>
            <div className={styles.header}>
              <h1>{product.title}</h1>
              <p>{product.subCategory}</p>
            </div>
            <section id={styles.productInfo}>
              <div className={styles.column}>
                <ImageZoomer
                  isSoldOut={isSoldOut()}
                  images={product.image_collection}
                />
                {lessThen850 ? (
                  <div className={styles.features}>
                    <h3>Features</h3>
                    <ul className={styles.list}>
                      {product.features &&
                        Object.entries(product.features).map(([key, p]) => {
                          return (
                            <li key={key} className={styles.list_item}>
                              {p}
                            </li>
                          )
                        })}
                    </ul>
                  </div>
                ) : (
                  <div className={styles.description}>
                    <h3>Description</h3>
                    <p>{product.description}</p>
                  </div>
                )}
              </div>
              <div className={styles.column_right}>
                {product.price === 0 || (
                  <div className={styles.prices}>{showPriceComponent()}</div>
                )}

                {lessThen850 ? (
                  <div className={styles.description}>
                    <h3>Description</h3>
                    <p>{product.description}</p>
                  </div>
                ) : (
                  <div className={styles.features}>
                    <h3>Features</h3>
                    <ul className={styles.list}>
                      {product.features &&
                        Object.entries(product.features).map(([key, p]) => {
                          return (
                            <li key={key} className={styles.list_item}>
                              {p}
                            </li>
                          )
                        })}
                    </ul>
                  </div>
                )}
                {product.warranty[0] === "0" ? (
                  <h4 className={styles.warranty}>No Warranty</h4>
                ) : (
                  <h4 className={styles.warranty}>
                    {product.warranty[0]} {product.warranty[1]} Warranty
                  </h4>
                )}

                {added ? (
                  <div className={styles.confirmation}>
                    <Check size={20} />
                    <p>"{product.title}" has been added to your cart.</p>
                  </div>
                ) : (
                  <div style={{ height: 20, marginBottom: "2rem" }} />
                )}

                {canOrder && product.price !== 0 && !isSoldOut() && (
                  <div className={styles.order}>
                    <div className={styles.qt_selector}>
                      <div
                        className={styles.qt_button + " " + styles.remove}
                        onClick={() => {
                          if (quantity === 1) return

                          setQuantity(quantity - 1)
                        }}
                      >
                        -
                      </div>
                      <div className={styles.qt_container}>{quantity}</div>
                      <div
                        className={styles.qt_button + " " + styles.add}
                        onClick={() => {
                          if (quantity >= 10) return
                          setQuantity(quantity + 1)
                        }}
                      >
                        +
                      </div>
                    </div>
                    <div
                      onClick={() => {
                        if (quantity === 0) {
                          return
                        }
                        let areEqual = false
                        let idList = []

                        if (
                          order &&
                          order.products !== undefined &&
                          order.products.length !== 0
                        ) {
                          idList = order.products.map((e) => e.product)

                          areEqual = true
                        }

                        if (localStorage.getItem("cart_key") === null) {
                          // creating a cart
                          const payload = {
                            product: {
                              product: product._id,
                              image: product.image_collection[0],
                              title: product.title,
                              // variant: variantList,
                              quantity: quantity,
                              price: product.price,
                              pageUrl: pageUrl,
                              userPosition: type,
                            },
                            user: user_id,
                          }
                          dispatch(createOrder(payload)).then((e) => {
                            localStorage.setItem("cart_key", e.payload.orderId)
                            const storageEvent = new Event("cart_key")
                            const modify_cart = new Event("modify_cart")
                            window.dispatchEvent(storageEvent)
                            window.dispatchEvent(modify_cart)
                            setAdded(true)
                          })
                        } else {
                          if (idList.includes(product._id) && areEqual) {
                            //Updating the cart
                            const index = idList.indexOf(product._id)
                            let temp = [...order.products]
                            let tempQuantity = temp[index].quantity
                            tempQuantity = tempQuantity + quantity

                            const updatedProduct = {
                              ...temp[index],
                              quantity: tempQuantity,
                            }

                            // Update the corresponding product in the cartPayload
                            const updatedProducts = [
                              ...cartPayload.products.slice(0, index),
                              updatedProduct,
                              ...cartPayload.products.slice(index + 1),
                            ]
                            cartPayload.products = updatedProducts

                            if (product.price === 0) {
                              cartPayload.totalPrice =
                                cartPayload.totalPrice +
                                product.price * quantity
                            } else {
                              cartPayload.totalPrice =
                                cartPayload.totalPrice +
                                product.price * quantity
                            }

                            dispatch(updateOrder(cartPayload)).then(() => {
                              const modify_cart = new Event("modify_cart")
                              window.dispatchEvent(modify_cart)
                              setAdded(true)
                            })
                          } else {
                            //Adding a new product to the cart
                            cartPayload.products = [
                              ...order.products,
                              {
                                product: product._id,
                                image: product.image_collection[0],
                                title: product.title,

                                quantity: quantity,
                                price: product.price,
                                pageUrl: pageUrl,
                              },
                            ]
                            if (product.price === 0) {
                              cartPayload.totalPrice =
                                cartPayload.totalPrice +
                                product.price * quantity
                            } else {
                              cartPayload.totalPrice =
                                cartPayload.totalPrice +
                                product.price * quantity
                            }

                            dispatch(updateOrder(cartPayload)).then(() => {
                              const modify_cart = new Event("modify_cart")
                              window.dispatchEvent(modify_cart)
                              setAdded(true)
                            })
                          }
                        }
                      }}
                      className={styles.add_btn}
                    >
                      Add to Cart
                    </div>
                  </div>
                )}
                {product.cAvailability || (
                  <p className={styles.not_available_message}>
                    This product is not available in your current region.
                  </p>
                )}
                <div className={styles.media}>
                  <InlineShareButtons
                    config={{
                      // url: pageUrl,
                      alignment: "center", // alignment of buttons (left, center, right)
                      color: "social", // set the color of buttons (social, white)
                      enabled: true, // show/hide buttons (true, false)

                      networks: [
                        // which networks to include (see SHARING NETWORKS)
                        "facebook",
                        "whatsapp",
                        "twitter",
                        "livejournal",
                      ],
                    }}
                  />
                </div>
                <div className={styles.review_main}>
                  <div className={styles.review}>
                    {product.rating ? (
                      <p className={styles.ratingNumber}>
                        {product.rating.toFixed(1)}
                      </p>
                    ) : (
                      <></>
                    )}
                    <Rating
                      onClick={handleRating}
                      initialValue={product.rating || 0}
                      allowFraction={true}
                      size={25}
                    />
                  </div>
                </div>

                <div className={styles.categoryContainer}>
                  <p>Category:</p>
                  <p
                    onClick={() =>
                      navigate(
                        `/products/${formatUrl(product.category)}/${formatUrl(
                          product.subCategory
                        )}`
                      )
                    }
                    className={styles.category}
                  >
                    {product.category}
                  </p>
                </div>
                {currentBrand && (
                  <div className={styles.categoryContainer}>
                    <p>Brand:</p>
                    <a
                      href={
                        currentBrand.url.startsWith("https")
                          ? currentBrand.url
                          : "https://www." + currentBrand.url
                      }
                      className={styles.category}
                    >
                      {product.brand}
                    </a>
                  </div>
                )}
              </div>
            </section>
            <HorizontalList products={productsFiltered} />
          </>
        )}
        <div className={styles.p_r}>
          {/* <VerticalList header="Latest" /> */}
          {/* <VerticalList header="Top Rated" /> */}
          {/* <VerticalList header="Featured" /> */}
        </div>
      </main>

      <Footer />
    </>
  )
}
