import React, { useEffect } from "react"
import styles from "./Footer.module.scss"
import { useDispatch, useSelector } from "react-redux"
import { getBrands } from "../../state/brandSlice"

import { useMediaPredicate } from "react-media-hook"

export default function Footer() {
  const lessThan600 = useMediaPredicate("(max-width: 600px)")

  const dispatch = useDispatch()
  const { brands } = useSelector((state) => state.brands)
  const { contact } = useSelector((state) => state.seo)

  useEffect(() => {
    dispatch(getBrands())
  }, [dispatch])

  const year = new Date().getFullYear()

  return (
    <footer className={styles.mainContainer}>
      <div className={styles.footerContainer}>
        {lessThan600 || (
          <div className={styles.footerColumn}>
            <p className={styles.heading}>Where to buy</p>
            <a
              rel="noreferrer"
              href="https://goo.gl/maps/cayKZo3BohDAZ3mw6"
              target="_blank"
              className={styles.text}
            >
              Lebanon
            </a>
            <a
              rel="noreferrer"
              href="https://goo.gl/maps/LyWEaLoK6pfN5mDq8"
              target="_blank"
              className={styles.text}
            >
              Cyprus
            </a>
          </div>
        )}
        {lessThan600 || (
          <div className={styles.pages}>
            <p className={styles.heading}>Pages</p>
            <a href="/" className={styles.text}>
              Home
            </a>
            {/* <a href="/about-us" className={styles.text}>
              About Us
            </a> */}
            <a href="/contact-us" className={styles.text}>
              Contact Us
            </a>
            <a href="/policy" className={styles.text}>
              Return Policy
            </a>
          </div>
        )}
        {/* {lessThan600 || (
          <div className={styles.footerColumn}>
            <p className={styles.heading}>Brands</p>
            {Object.entries(brands).map(([key, value]) => {
              return (
                <p key={key} className={styles.text}>
                  {value.brand}
                </p>
              )
            })}
          </div>
        )} */}

        <div className={styles.footerColumn}>
          <p className={styles.heading}>Contact Us</p>
          {contact &&
            contact.contacts &&
            Object.entries(contact.contacts).map(([key, value]) => {
              return (
                <a
                  rel="noreferrer"
                  href={`mailto: ${value.example}`}
                  target="_blank"
                  key={key}
                  className={styles.text}
                >
                  {value.example}
                </a>
              )
            })}
        </div>

        {/* <div className={styles.newsletter}>
          <p className={styles.heading}>
            Subscribe to our Newsletter
          </p>

          <link
            href="https://d2hphlhl5wdf3o.cloudfront.net/client-108851/subscription-form/a31ce247f376464fbc31b66e951631c7/script.css"
            rel="stylesheet"
          />
          <div
            is="diq-div"
            id="diq-container-a31ce247f376464fbc31b66e951631c7"></div>
          <script src="https://d2hphlhl5wdf3o.cloudfront.net/client-108851/subscription-form/a31ce247f376464fbc31b66e951631c7/script.js"></script>
        </div> */}
      </div>

      <em>Copyright © {year} Compuonelb All rights reserved</em>
    </footer>
  )
}
