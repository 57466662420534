import React, { useState, useEffect, useRef } from "react"
import styles from "./Product.module.scss"

import Edit from "../../../icons/Edit"
import Delete from "../../../icons/Delete"
import { useDispatch, useSelector } from "react-redux"
import {
  setProduct,
  deleteProduct,
  handleVisibility,
  handleDiscount,
  handleToggleSoldOut,
  reactivateLoading,
  addProduct,
  handlePinned,
} from "../../../state/productSlice"
import NotVisible from "../../../icons/NotVisible"
import Pin from "../../../icons/Pin"
import Duplicate from "../../../icons/Duplicate"
import PinFilled from "../../../icons/PinFilled"

export default function Product(props) {
  const product = props.product

  const [countries, setCountries] = useState(product.countries || [])
  const divRef = useRef()
  const dispatch = useDispatch()

  const handleDelete = () => {
    dispatch(deleteProduct(product._id))
  }

  const toggleVisibility = () => {
    dispatch(handleVisibility(product._id))
  }

  const togglePinned = () => {
    dispatch(handlePinned(product._id))
  }

  const toggleSoldOut = () => {
    const payload = {
      id: product._id,
      isSoldOut: product.isSoldOut,
    }

    dispatch(handleToggleSoldOut(payload))
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (divRef.current && !divRef.current.contains(event.target)) {
        // click outside the div detected
      }
    }

    document.addEventListener("click", handleClickOutside)

    return () => {
      document.removeEventListener("click", handleClickOutside)
    }
  }, [])

  let price = product.price

  function onChange(countryName, checked) {
    let temp = [...countries]
    let index = temp.findIndex((c) => c.name === countryName)
    let country = { ...temp[index] }
    country.isOutOfStock = checked
    temp[index] = country
    setCountries(temp)
  }
  function onSave() {
    const payload = {
      id: product._id,
      countries: countries,
    }

    dispatch(handleToggleSoldOut(payload))
  }

  return (
    <div className={styles.mainCard}>
      <div>
        <img
          className={styles.image}
          alt="product_image"
          src={process.env.REACT_APP_BASE_URL + product.image_collection[0]}
        />
      </div>
      <div>
        <p>{product.title}</p>
      </div>
      <div>
        <p>{product.category}</p>
      </div>
      <div>
        <p>{price}</p>
      </div>
      <div className={styles.action}>
        <div className={styles.non_flex} title="Edit">
          <Edit
            onClick={() => {
              dispatch(reactivateLoading())
              dispatch(setProduct(product))
              props.setVisible()
            }}
          />
        </div>
        <div className={styles.non_flex}>
          <Duplicate
            onClick={() => {
              const payload = {
                product: {
                  category: product.category,
                  subCategory: product.subCategory,
                  properties: product.components,
                  description: product.description,
                  features: product.features,
                  imageLinks: product.image_collection,
                  title: product.title,
                  brand: product.brand,
                  imageFiles: [],
                  pricingGroupes: product.pricingGroupes,
                  warranty: product.warranty,
                },
              }
              console.log(payload)

              dispatch(addProduct(payload))
            }}
            size={20}
            fill="#999"
          />
        </div>
        <div className={styles.non_flex} title="Delete">
          <Delete onClick={handleDelete} />
        </div>

        <div
          className={styles.non_flex}
          title={product.isVisible ? "Hide" : "Show"}
        >
          <NotVisible
            fill={product.isVisible ? "#999" : "#e52627"}
            onClick={toggleVisibility}
          />
        </div>
        <div className={styles.non_flex}>
          {product.isPinned ? (
            <PinFilled onClick={togglePinned} />
          ) : (
            <Pin onClick={togglePinned} />
          )}
        </div>

        {/*  <div className={styles.discount} title="Set discount">
          <Discount
            onClick={() => setDiscount(!discount)}
            className={styles.discount_svg}
            size="20px"
            fill={product.discount === 0 ? "#999" : "#2b79c2"}
          />
           {discount === true ? (
            <div ref={divRef} className={styles.container}>
              <input
                onChange={(e) => {
                  setValue(e.target.value)
                }}
                placeholder="Discount"
                className={styles.input}
                type="number"
                id="price"
                name="price"
                step="5"
                min={0}
                max={100}
                value={value}
              />
              <div className={styles.buttons}>
                <p
                  onClick={() => {
                    const payload = {
                      id: product._id,
                      discount:
                        document.getElementById("price").value,
                    }
                    dispatch(handleDiscount(payload))
                    setDiscount(false)
                  }}
                  className={styles.text_button}>
                  Save
                </p>
                <p
                  onClick={() => {
                    setDiscount(false)
                  }}
                  className={styles.cancel}>
                  Cancel
                </p>
              </div>
            </div>
          ) : null}
        </div> */}

        {/*  <div className={styles.non_flex} title="Mark as availale">
          <SoldOut
            onClick={() => {
              setOOS(true)
            }}
            fill={"#999"}
            size={"20"}
          />

           {oOS ? (
            <div className={styles.container}>
              {Object.entries(product.countries).map(([key, c]) => {
                return (
                  <CountrySoldOut
                    onChange={onChange}
                    key={key}
                    c={c}
                  />
                )
              })}
              <div className={styles.buttons}>
                <p
                  onClick={() => {
                    onSave()
                    setOOS(false)
                  }}
                  className={styles.text_button}>
                  Save
                </p>
                <p
                  onClick={() => setOOS(false)}
                  className={styles.cancel}>
                  Cancel
                </p>
              </div>
            </div>
          ) : (
            <></>
          )} 
        </div>
        */}
      </div>
    </div>
  )
}
