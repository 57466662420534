import React, { useEffect } from "react"
import Layout from "../../components/layout/Layout"
import Header from "../../components/Header/Header"
import styles from "./News.module.scss"
import { useDispatch, useSelector } from "react-redux"
import { getNewsById } from "../../state/newsSlice"

export default function News() {
  const newsId = window.location.pathname.split("/")[2]

  const { news } = useSelector((state) => state.news)

  const dispatch = useDispatch()

  useEffect(() => {
    console.log("newsId")
    dispatch(getNewsById(newsId))
  }, [dispatch, newsId])

  return (
    <main>
      <Layout />
      <Header />
      <div className={styles.strong_news}>
        <div className={styles.side_image}>
          <img
            alt={news?.title}
            src={process.env.REACT_APP_BASE_URL + news?.vrImage}
          />
        </div>
        <div className={styles.content}>
          <div className={styles.image}>
            <img
              alt={news?.title}
              src={process.env.REACT_APP_BASE_URL + news?.hrImage}
            />
          </div>
          <h1 className={styles.title}>{news?.title}</h1>
          <p className={styles.description}>{news?.description}</p>
          <div className={styles.article_info}>
            <span className={styles.category}>{news?.category}</span>
          </div>
        </div>
      </div>
    </main>
  )
}
