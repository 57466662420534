import React, { useEffect, useState } from "react"
import Header from "../components/Header/Header"
import Footer from "../components/footer/Footer"

import ProductsList from "../components/ProductsList/ProductsList"
import ContactPage from "../components/contact/ContactPage"
import { Helmet, HelmetProvider } from "react-helmet-async"
import { useDispatch, useSelector } from "react-redux"
import { getMeta } from "../state/seoSlice"

export default function Contact() {
  const [keyword, setKeyword] = useState("")
  const dispatch = useDispatch()
  const { meta } = useSelector((state) => state.seo)
  var title = ""
  var description = ""
  for (let i = 0; i < meta.length; i++) {
    if (meta[i].url === "contact-us") {
      title = meta[i].metaTitle
      description = meta[i].meteaDescription
    }
  }

  useEffect(() => {
    dispatch(getMeta())
  }, [dispatch])

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />

          <link
            rel="canonical"
            href={`${process.env.REACT_APP_URL}/contact-us`}
          />
        </Helmet>
      </HelmetProvider>
      <Header onChange={(e) => setKeyword(e.target.value)} />
      <ContactPage />
      <Footer />
    </>
  )
}
