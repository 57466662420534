import React, { useState, useRef, useEffect } from "react"
import Drawer from "react-modern-drawer"
import "react-modern-drawer/dist/index.css"
import styles from "./DrawerBody.module.scss"
import { useNavigate } from "react-router-dom"

import Close from "../../../icons/Close"
import { useSelector } from "react-redux"
import { formatUrl } from "../../../helpers/Formats"

export default function DrawerBody(props) {
  const [openSection, setOpenSection] = useState(null)

  let navigate = useNavigate()

  const toggleDrawer = () => {
    props.toggleDrawer(false)
  }

  const { categories } = useSelector((state) => state.categories)
  return (
    <Drawer
      size={"100%"}
      open={props.isOpen}
      onClose={toggleDrawer}
      direction="top"
    >
      <div className={styles.main}>
        <div className={styles.close_container}>
          <Close
            onClick={toggleDrawer}
            height={"20px"}
            width={"20px"}
            fill={"#174e92"}
          />
        </div>
        <ul className={styles.list}>
          {Object.entries(categories).map(([key, value]) => {
            return (
              <div
                key={key}
                onClick={() => {
                  openSection === key
                    ? setOpenSection(null)
                    : setOpenSection(key)
                }}
              >
                <p className={styles.list_item}>{value.name}</p>
                {openSection === key && (
                  <ul>
                    {Object.entries(value.subCategories).map(
                      ([key_sub, value_sub]) => (
                        <li
                          key={key_sub}
                          onClick={() => {
                            toggleDrawer()
                            navigate(`/${value.name}/${value_sub}/1`)
                          }}
                          className={styles.list_item_s}
                        >
                          {value_sub}
                        </li>
                      )
                    )}
                  </ul>
                )}
              </div>
            )
          })}
        </ul>
      </div>
    </Drawer>
  )
}
